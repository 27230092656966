<template>
  <article v-if="data.projects.length">
    <h1 class="projects-tabs__title">Другие проекты</h1>
    <div class="projects-tabs__content" style="padding-top: 32px">
      <div class="projects-tab-content">
        <div class="projects-tab-content__content">
          <div class="projects-tab-content__cards">
            <ProjectCardComponent v-for="(project, i) in data.projects" :key="i" :data="project" />
          </div>
        </div>
      </div>
    </div>
  </article>
</template>

<script>
import ProjectCardComponent from "components/ProjectCardComponent.vue";

export default {
  name: "ProjectPageProjectCards",
  components: { ProjectCardComponent },
  computed: {
    data() {
      return this.$store.state.project_page;
    },
  },
};
</script>

<style lang="stylus">
.projects-tabs__title {
  font-weight: 400;
  font-size: 1.875rem;
  line-height: 35px;
  color: var(--blue-o5);
  margin: 0;
  padding-top: 60px;
}
.projects-tab-content__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 60px;
}
</style>
